<template>
  <div class="content-container">
    <PageTitle :title="$t('MISSION_VIDEOS.TITLE')" class="page-title" />
    <h1 class="page-section-title section-title">
      {{ $t("MISSION_VIDEOS.NEW") }}
    </h1>
    <BackButton
      :text="$t('BACK_OVERVIEW')"
      :route-name="ROUTE_NAMES_CMS.MISSION_VIDEOS"
    />
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>
        <div v-if="initialData">
          <InputField
            :field-title="$t('MISSION_VIDEOS.TITLE')"
            :field-name="'name'"
            :rules="'required'"
            :type="'text'"
            :value="initialData.name"
            :placeholder="$t('MISSION_VIDEOS.TITLE_PLACEHOLDER')"
            :cy-selector="'name-input'"
            :input-icon-error="require('../../../assets/icons/icn_error.svg')"
            :api-error-text="''"
            class="form-element"
          />
          <ImageInput
            :title="$t('MISSION_VIDEOS.THUMBNAIL')"
            :media-files="[initialData.thumbnail]"
            :start-file="initialData.thumbnail?.url"
            @file="updateThumbnail"
          />
  
          <UploadVideo
            :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.REGULAR')"
            :value="initialData.videoUrl"
            @update-link="updateOriginal"
            @isValid="(value) => linkIsValid(0,value)"/>

          <UploadVideo
            :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.SUBTITLES')"
            :value="initialData.videoSubtitlesUrl"
            @update-link="updateSubtitles"
            @isValid="(value) => linkIsValid(1,value)"/>
  
          <UploadVideo
            :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.SIGN_LANGUAGE')"
            :value="initialData.videoDeafUrl"
            @update-link="updateDeaf"
            @isValid="(value) => linkIsValid(2,value)"/>
  
          <UploadVideo
            :field-title="$t('DIAS.VIDEO_URL') + ' - ' + $t('ASSISTANCE.AUDIO_DESCRIPTION')"
            :value="initialData.videoBlindUrl"
            @update-link="updateBlind"
            @isValid="(value) => linkIsValid(3,value)"/>  
          </div>
        </template>
      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t("SAVE") }}
        </button>
      </template>
    </FormWrapper>
  </div>
</template>

<script>
import { GET_MISSION_VIDEO, EDIT_MISSION_VIDEO } from '@/store/modules/cms/actions'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import ImageInput from '../../../components/elements/inputFields/ImageInput.vue'
import UploadVideo from '@/components/elements/inputFields/UploadVideo.vue'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'

export default {
  name: 'VideoEdit',
  components: {
    FormWrapper,
    ImageInput,
    UploadVideo,
    InputField,
    PageTitle,
    BackButton,
  },
  data() {
    return {
      isValid: [true, true, true, true],
      data: null,
      id: this.$route.params.id,
      initialData: null,
      originalVideo: null,
      videoSubtitlesUrl: null,
      videoBlindUrl: null,
      videoDeafUrl: null,
      ROUTE_NAMES_CMS,
      thumbnail: null
    }
  },
  created() {
    this.getInitialData()
  },
  methods: {
    linkIsValid(index, value) {
      this.isValid[index] = value
    },
    getInitialData() {
      this.$store.dispatch(GET_MISSION_VIDEO, this.id).then(response => {
        this.initialData = response.data;
        this.originalVideo = response.data.videoUrl
        this.videoSubtitlesUrl = response.data.videoSubtitlesUrl
        this.videoBlindUrl = response.data.videoBlindUrl
        this.videoDeafUrl = response.data.videoDeafUrl
        this.thumbnail = response.data.thumbnail
      })
    },
    updateOriginal(videoUrl) {
      this.originalVideo = videoUrl
    },
    updateSubtitles(videoUrl) {
      this.videoSubtitlesUrl = videoUrl
    },
    updateBlind(videoUrl) {
      this.videoBlindUrl = videoUrl
    },
    updateDeaf(videoUrl) {
      this.videoDeafUrl = videoUrl
    },
    updateThumbnail(image) {
      this.thumbnail = image
    },
    validateData(data) {
      if (!this.isValid.every(x => x)) {
        return
      }

      data.thumbnail = this.thumbnail
      data.videoUrl = this.originalVideo
      data.videoSubtitlesUrl = this.videoSubtitlesUrl
      data.videoDeafUrl = this.videoDeafUrl
      data.videoBlindUrl = this.videoBlindUrl
    
      this.$store.dispatch(EDIT_MISSION_VIDEO, { payload: data, id: this.id }).then(response => {
        if (response && (response.status === 201 || response.status === 204)) {
          this.$router.push({ name: ROUTE_NAMES_CMS.MISSION_VIDEOS })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";

.video-uploader{
  margin-top: rem(20);
}

</style>
